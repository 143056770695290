<template>
  <nav>
    <ul class="flex items-center -space-x-px h-8 text-sm">
      <li
        class="cursor-pointer flex items-center justify-center px-3 h-8 ms-0 leading-tight text-neutral-200 bg-white border border-e-0 border-neutral-80 rounded-s-lg hover:bg-neutral-50"
        :class="{ '!cursor-not-allowed': pageNum === 1 }"
        @click="onChange(-1)"
      >
        <font-awesome-icon icon="fa-solid fa-chevron-left" />
      </li>
      <li
        v-for="num in paginationfirst"
        :key="num"
        class="cursor-pointer flex items-center justify-center px-3 h-8 leading-tight text-neutral-200 bg-white border border-neutral-80 hover:bg-neutral-50"
        :class="{
          '!cursor-not-allowed !text-primary-100 !bg-primary-50':
            pageNum === num
        }"
        @click="setNumber(num)"
      >
        {{ num }}
      </li>
      <template v-if="pages.total > 4">
        <span
          class="cursor-default flex items-center justify-center px-3 h-8 leading-tight text-neutral-200 bg-white border border-neutral-80 hover:bg-neutral-50"
          >...
        </span>
        <li
          class="cursor-pointer flex items-center justify-center px-3 h-8 leading-tight text-neutral-200 bg-white border border-neutral-80 hover:bg-neutral-50"
          :class="{
            '!cursor-not-allowed !text-primary-100 !bg-primary-50':
              pageNum === pages.total
          }"
          @click="setNumber(pages.total)"
        >
          {{ pages.total }}
        </li>
      </template>
      <li
        class="cursor-pointer flex items-center justify-center px-3 h-8 leading-tight text-neutral-200 bg-white border border-neutral-80 rounded-e-lg hover:bg-neutral-50"
        :class="{ '!cursor-not-allowed': pageNum === pages.total }"
        @click="onChange(1)"
      >
        <font-awesome-icon icon="fa-solid fa-chevron-right" />
      </li>
    </ul>
  </nav>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const range = (min, max) => [...Array(max - min + 1).keys()].map((i) => i + min)

const emit = defineEmits(['onPageChange'])
const props = defineProps({
  pages: Object
})

const paginationfirst = ref([])
const pageNum = ref(1)

watch(
  () => props.pages.total,
  () => setInitialPagination()
)
watch(
  () => props.pages.current,
  (num) => {
    if (pageNum.value != num) pageNum.value = num
  }
)
watch(
  () => route.query.page,
  (page) => {
    if (page) {
      pageNum.value = Number(page)
    } else {
      pageNum.value = props.pages.current
    }
  },
  { immediate: true }
)

watch(
  () => pageNum.value,
  () => {
    setVisiblePages()
  }
)

onMounted(() => {
  setInitialPagination()
  setVisiblePages()
})

function setInitialPagination() {
  paginationfirst.value = range(1, Math.min(props.pages.total, 4))
}

function setVisiblePages() {
  if (props.pages.total > 4) {
    if (pageNum.value >= props.pages.total - 1) {
      // At the end of the pages range, show the last 4 pages
      paginationfirst.value = range(
        props.pages.total - 4,
        props.pages.total - 1
      )
    } else if (pageNum.value >= 4) {
      // Not at the end, but beyond page 4, show two pages before and up to one page after
      paginationfirst.value = range(
        Math.max(pageNum.value - 2, 1),
        Math.min(pageNum.value + 1, props.pages.total - 1)
      )
    } else setInitialPagination()
  } else setInitialPagination()
}

function setNumber(num) {
  if (pageNum.value === num) return
  pageNum.value = num
  emit('onPageChange', pageNum.value)
}
function onChange(num) {
  if (
    (pageNum.value === 1 && num === -1) ||
    (pageNum.value === props.pages.total && num === 1)
  )
    return
  pageNum.value = num + props.pages.current
  emit('onPageChange', pageNum.value)
}
</script>
