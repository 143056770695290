<template>
  <div
    class="group relative grid gap-y-3 grid-rows-[auto_h-min_h-min_h-min_auto] grid-cols-[1fr_1fr] h-auto w-full shadow bg-white p-4 rounded cursor-pointer"
    data-cy="project_card"
    @click="onClick"
  >
    <ButtonEditCard class="edit top-2 !opacity-0 group-hover:!opacity-100">
      <template #option>
        <span @click="showProjectModal(data.id)">{{
          $t('shared.button.move_to_folder')
        }}</span>
      </template>
    </ButtonEditCard>
    <router-link
      data-cy="project_identifier"
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="flex flex-col gap-1 row-span-1 col-span-2 border-b border-neutral-80 pb-3"
    >
      <TextSmall medium>
        {{ data.attributes.identifier }}
      </TextSmall>
      <TextTiny neutral class="!tabletPortrait:!text-sm">
        {{ data.attributes.name }}
      </TextTiny>
    </router-link>
    <div class="flex flex-col col-span-2">
      <Label :title="`${$t('shared.label.folder')}:`" />
      <TextTiny
        class="max-w-fit bg-neutral-50 rounded-sm py-[1px] px-3 tabletPortrait:!text-sm inline-block gap-1 cursor-default truncate"
        :class="{
          'cursor-pointer': data.attributes.folder,
          '!text-neutral-100': !data.attributes.folder
        }"
        @click="goToFolder(data.attributes.folder)"
      >
        <i
          v-if="data.attributes.folder"
          class="fas fa-folder-open mr-1 text-neutral-200 !leading-[1.5]"
        />
        {{
          data.attributes.folder
            ? data.attributes.folder.attributes.name
            : $t('shared.label.no_folder')
        }}
      </TextTiny>
    </div>
    <router-link
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="flex flex-col col-span-2"
    >
      <Label :title="`${$t('shared.label.workflow')}:`" />
      <TextTiny
        class="bg-neutral-50 rounded-sm w-fit py-[1px] px-3 tabletPortrait:!text-sm flex gap-1"
      >
        <span
          ref="service"
          class="w-[10px] h-[10px] block rounded-xs mr-1 mt-1.25"
          :class="{
            '!bg-success-100': status.theme === 'green',
            '!bg-error-100': status.theme === 'red',
            '!bg-warning-200': status.theme === 'yellow',
            '!bg-info-100': status.theme === 'blue',
            '!bg-neutral-200': status.theme === 'gray'
          }"
        ></span>
        {{ data.attributes.workflow.attributes.display_name }}
      </TextTiny>
    </router-link>
    <router-link
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="flex flex-col"
    >
      <Label :title="`${$t('shared.label.price_without_vat')}:`" />
      <OutputText
        :text="`${formatPrice(
          data.attributes.price.amount,
          data.attributes.price.currency
        )}`"
      />
    </router-link>
    <router-link
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="flex flex-col"
    >
      <Label :title="`${$t('shared.label.date_created')}:`" />
      <OutputText :text="`${formatDate(data.attributes.created_at)}`" />
    </router-link>
    <router-link
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="flex flex-col"
    >
      <Label
        :title="`${
          data.attributes.workflow.attributes.identifier === 'copyediting'
            ? $t('shared.label.languages')
            : $t('shared.label.from')
        }:`"
      />
      <OutputText
        :text="`${capitalize(
          data.attributes.workflow.attributes.identifier !== 'copyediting'
            ? data.attributes.source_language
            : targetList
        )}`"
      />
    </router-link>
    <router-link
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="flex flex-col"
    >
      <Label
        v-if="data.attributes.workflow.attributes.identifier !== 'copyediting'"
        :title="`${$t('shared.label.to')}:`"
      />
      <OutputText
        v-if="data.attributes.workflow.attributes.identifier !== 'copyediting'"
        :text="`${capitalize(targetList)}`"
      />
    </router-link>
    <router-link
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="flex flex-col"
    >
      <Label :title="`${$t('shared.label.preferred_deadline')}:`" />
      <OutputText
        :text="`${formatDeadline(data.attributes.preferred_deadline)}`"
      />
    </router-link>
    <router-link
      :to="{ name: 'clientProject', params: { id: data.id } }"
      class="col-span-2 w-full border-t border-neutral-80 pt-3"
    >
      <TextSmall
        class="w-full flex items-center justify-center rounded p-1 tabletPortrait:!text-base"
        :class="{
          '!bg-success-50 !text-success-100': status.theme === 'green',
          '!bg-error-50 !text-error-100': status.theme === 'red',
          '!bg-warning-50 !text-warning-200': status.theme === 'yellow',
          '!bg-info-50 !text-info-100': status.theme === 'blue',
          '!bg-neutral-50 !text-neutral-200': status.theme === 'gray'
        }"
      >
        {{ status.text }}
      </TextSmall>
    </router-link>
  </div>
</template>
<script setup>
import { ProjectService } from '@/api/new/services/project.service'
import ButtonEditCard from '@/components/shared/ButtonEditCard'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import Label from '@/components/shared/label/Label'
import OutputText from '@/components/shared/output/OutputText'
import { capitalize } from '@/helpers/format'
import moment from 'moment'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()

const service = ref(null)

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const setProjectId = (payload) =>
  store.commit('workspace/setProjectId', payload)
const toggleSelectFolderModal = (payload) =>
  store.commit('modals/toggleSelectFolderModal', payload)

const status = computed(() => {
  return ProjectService.getStatus(props.data.attributes.status)
})

const targetList = computed(() => {
  return props.data.attributes.target_languages.join(', ')
})

function onClick(e) {
  if (e.target.classList.contains('project')) {
    router.push({
      name: 'clientProject',
      params: { id: props.data.id }
    })
  }
}

function showProjectModal(id) {
  toggleSelectFolderModal(true)
  setProjectId(id)
}

function formatPrice(price, currency) {
  if (price === 0) return '-'
  return `${price / 100} ${currency}`
}

function formatDate(date) {
  if (!date) return '-'
  return moment(date).format('YYYY-MM-DD')
}

function formatDeadline(date) {
  if (!date) return '-'
  return moment(date).format('YYYY-MM-DD HH:mm')
}

function goToFolder(folder) {
  if (!folder) return

  router.push({ name: 'clientFolder', params: { id: folder.id } })
}
</script>
