<template>
  <div ref="selectRef" class="relative">
    <div
      class="bg-white block relative border border-neutral-80 text-black text-sm rounded-lg w-full focus:border-primary-100 hover:border-primary-100"
      :class="{
        'max-h-max !py-0': sm,
        '!border-error-100 hover:!border-error-100': required,
        '!bg-neutral-50': disabled && theme === 'default',
        '!border-none': theme !== 'default',
        '!bg-error-50': theme === 'error',
        '!bg-success-50': theme === 'success',
        '!bg-neutral-50 !text-neutral-200': theme === 'gray'
      }"
    >
      <template v-if="loading">
        <LocalLoader is-tiny :loading="loading" />
        <div
          class="px-2.5 py-2 blur-[1.5px] w-full max-w-[90%] overflow-hidden text-ellipsis whitespace-nowrap"
        >
          <TextSmall
            :lightNeutral="!value"
            :errorDark="theme === 'error'"
            :successDark="theme === 'success'"
          >
            {{ value || placeholder }}
          </TextSmall>
        </div>
      </template>
      <template v-else>
        <div
          :data-cy="dataCy || 'select__selection'"
          class="relative w-full h-[37px] rounded-lg px-2.5 py-2 !pr-[2.5rem] text-sm border-none focus:ring-transparent cursor-pointer"
          :class="{
            '!cursor-not-allowed': disabled,
            '!h-[1.85rem] !py-0 !flex !items-center': sm,
            'min-h-[37px] !pb-0.5 !pt-1.25 !h-auto !max-h-[200px] overflow-y-auto':
              $slots.multiselect
          }"
          @click.prevent.stop="handleToggleSelect"
        >
          <template v-if="!$slots.multiselect">
            <TextSmall
              :lightNeutral="!value"
              :errorDark="theme === 'error'"
              :successDark="theme === 'success'"
              :medium="theme !== 'default'"
              :class="{
                'w-full max-w-[90%] overflow-hidden text-ellipsis whitespace-nowrap':
                  !allowMultiline,
                '!text-xs': sm
              }"
            >
              {{ value || placeholder }}
            </TextSmall>
          </template>
          <template v-if="$slots.multiselect">
            <div class="-mt-2" @click.prevent.stop="handleToggleSelect">
              <slot name="multiselect" />
            </div>
          </template>
          <font-awesome-icon
            :icon="theme === 'default' ? 'fa-chevron-down' : 'caret-down'"
            class="absolute text-xs cursor-pointer font-medium right-4 top-1/2 -translate-y-1/2"
            :class="{
              'text-black': theme === 'default',
              'text-error-200': theme === 'error',
              'text-success-200': theme === 'success',
              'text-neutral-100': theme === 'gray'
            }"
          />
        </div>
      </template>
    </div>

    <!-- DROPDOWN -->
    <div
      v-if="!loading"
      v-show="openSelectId === dataCy"
      ref="dropdownRef"
      class="absolute z-[2002] shadow bg-white block border border-neutral-80 text-black text-sm rounded-lg w-full p-2.5"
      :class="{
        dropdownAbove: position === 'top',
        dropdownBelow: position === 'bottom'
      }"
      @click="handleClose"
      v-click-outside="handleClose"
    >
      <div
        v-if="allowSearch || multiselect"
        class="w-full flex flex-col gap-3 mb-2"
        :class="{ 'border-b border-neutral-80 pb-1': multiselect }"
      >
        <InputField
          v-if="allowSearch"
          :id="`search_${dataCy}`"
          small
          clearable
          prefix-icon="fa-magnifying-glass"
          :value="search"
          :placeholder="$t('shared.label.search')"
          @inputChange="handleSearchInput"
          @click.prevent.stop="inputSearch = true"
        />
        <div
          v-if="multiselect && allowSelectAll"
          class="w-full flex items-center justify-between"
        >
          <TextTiny
            data-cy="custom_select_all"
            neutral
            medium
            class="cursor-pointer hover:text-primary-100"
            @click.prevent="emit('selectAll', true)"
          >
            {{ $t('shared.label.select_all') }}
          </TextTiny>
          <TextTiny
            data-cy="custom_deselect_all"
            neutral
            medium
            class="cursor-pointer hover:text-primary-100"
            @click.prevent="emit('selectAll', false)"
          >
            {{ $t('shared.label.unselect_all') }}
          </TextTiny>
        </div>
      </div>
      <div class="max-h-[150px] overflow-y-auto flex flex-col gap-0.5">
        <slot />
      </div>
    </div>
  </div>
</template>
<script setup>
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import InputField from '@/components/shared/input/InputField'
import LocalLoader from '@/components/shared/loaders/LocalLoader'
import i18n from '@/i18n'
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const emit = defineEmits(['search', 'selectAll', 'close'])
const props = defineProps({
  dataCy: String,
  placeholder: String,
  value: String | Number,
  fitContent: { type: Boolean, default: false },
  required: {
    type: Boolean,
    default: false
  },
  allowMultiline: {
    type: Boolean,
    default: false
  },
  allowSelectAll: {
    type: Boolean,
    default: true
  },
  rounded: Boolean,
  sm: Boolean,
  loading: Boolean,
  allowSearch: Boolean,
  multiselect: Boolean,
  disabled: Boolean,
  position: String,
  theme: {
    type: String,
    default: 'default'
  }
})

const openSelectId = computed(() => store.state.workspace.openSelectId)
const setOpenSelectId = (payload) =>
  store.commit('workspace/setOpenSelectId', payload)

const selectRef = ref(null)
const dropdownRef = ref(null)
const dropdownAbove = ref(false)
const search = ref('')
const inputSearch = ref(false)

onMounted(() => {
  window.addEventListener('resize', adjustDropdownPosition)
  window.addEventListener('scroll', adjustDropdownPosition, true)
  window.addEventListener('keydown', onKeyDown)
})

onUnmounted(() => {
  window.removeEventListener('resize', adjustDropdownPosition)
  window.removeEventListener('scroll', adjustDropdownPosition, true)
  window.removeEventListener('keydown', onKeyDown)
  search.value = ''
  inputSearch.value = false
  setOpenSelectId(null)
  emit('close')
})

function handleToggleSelect() {
  if (props.disabled) return
  setOpenSelectId(openSelectId.value === props.dataCy ? null : props.dataCy)
  nextTick(() => {
    adjustDropdownPosition()
  })
}

function handleClose(e) {
  if (props.multiselect && e.target.id === `search_${props.dataCy}`) return
  setOpenSelectId(null)
  inputSearch.value = false
  emit('close')
}

function onKeyDown(e) {
  if (!inputSearch.value && e.key.length === 1 && openSelectId.value) {
    search.value += e.key
    return
  }
  if (!inputSearch.value && e.key === 'Backspace') {
    search.value = search.value.slice(0, -1)
  }
}

function adjustDropdownPosition() {
  if (!selectRef.value || !dropdownRef.value || props.position) return

  const parentRect = selectRef.value.getBoundingClientRect()
  const dropdownHeight = dropdownRef.value.offsetHeight
  const windowHeight = window.innerHeight

  dropdownAbove.value = parentRect.bottom + dropdownHeight > windowHeight
  dropdownRef.value.classList.toggle('dropdownAbove', dropdownAbove.value)
  dropdownRef.value.classList.toggle('dropdownBelow', !dropdownAbove.value)
}

function handleSearchInput({ value }) {
  search.value = value
}

watch(
  () => search.value,
  () => {
    emit('search', search.value)
  }
)

watch(
  () => openSelectId.value,
  () => (search.value = '')
)
</script>
<style lang="scss" scoped>
.dropdownBelow {
  top: 100%;
  bottom: auto;
}
.dropdownAbove {
  top: auto;
  bottom: 100%;
}
</style>
