<template>
  <div
    class="grid grid-cols-[repeat(auto-fill,_minmax(20.875rem,_1fr))] auto-rows-auto grid-flow-dense gap-5"
    :class="{ '!grid-cols-1 !gap-y-3': listView }"
    data-cy="projects_grid"
  >
    <SectionCard v-if="listView">
      <Table :columns="columns"><slot /></Table>
    </SectionCard>
    <template v-else>
      <slot />
    </template>
  </div>
</template>
<script setup>
import SectionCard from '@/components/shared/section/SectionCard'
import Table from '@/components/shared/table/Table'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const columns = ref([
  {
    field: 'project',
    label:
      $cookies.get('userType') !== 'supplier'
        ? $t('shared.label.project')
        : $t('shared.label.task'),
    width: 200
  },
  {
    field: 'date',
    label:
      $cookies.get('userType') !== 'supplier'
        ? $t('shared.label.date_created')
        : $t('shared.label.deadline'),
    width: 90
  },
  {
    field: 'preferred_deadline',
    label: $t('shared.label.preferred_deadline'),
    width: 100
  },
  {
    field: 'from',
    label: $t('shared.label.from'),
    width: 50
  },
  {
    field: 'to',
    label: $t('shared.label.to'),
    width: 90
  },
  {
    field: 'progress',
    label: $t('shared.label.progress'),
    width: 100
  },
  {
    field: 'actions',
    label: '',
    width: 30
  }
])

const showListView = computed(() => store.state.account.showListView)
const showSupplierListView = computed(
  () => store.state.account.showSupplierListView
)

const listView = computed(() =>
  $cookies.get('userType') === 'supplier'
    ? showSupplierListView.value
    : showListView.value
)
</script>
