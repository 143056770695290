<template>
  <tr
    class="group !bg-neutral-50 cursor-pointer"
    :class="{ '!bg-white': whiteBg }"
    @click="onClick"
  >
    <TableCell>
      <div data-cy="project_identifier" class="flex flex-col gap-1">
        <TextSmall medium truncate>{{ data.attributes.identifier }}</TextSmall>
        <TextTiny lightNeutral truncate class="tabletPortrait:!text-sm">
          {{ data.attributes.name }}
        </TextTiny>
      </div>
    </TableCell>
    <TableCell>
      <OutputText
        truncate
        :text="`${formatDate(data.attributes.created_at)}`"
      />
    </TableCell>
    <TableCell>
      <OutputText
        truncate
        :text="`${formatDeadline(data.attributes.preferred_deadline)}`"
      />
    </TableCell>
    <TableCell>
      <OutputText
        truncate
        :text="`${capitalize(
          data.attributes.workflow.attributes.identifier !== 'copyediting'
            ? data.attributes.source_language
            : targetList
        )}`"
      />
    </TableCell>
    <TableCell>
      <OutputText
        v-if="data.attributes.workflow.attributes.identifier !== 'copyediting'"
        truncate
        :text="`${capitalize(targetList)}`"
      />
    </TableCell>
    <TableCell>
      <div
        class="text-center p-1 px-2 rounded-lg"
        :class="{
          '!bg-success-50': status.theme === 'green',
          '!bg-error-50': status.theme === 'red',
          '!bg-warning-50': status.theme === 'yellow',
          '!bg-info-50 [&>.textSmall]:!text-info-100': status.theme === 'blue',
          '!bg-neutral-50': status.theme === 'gray'
        }"
      >
        <TextSmall
          truncate
          :success="status.theme === 'green'"
          :error="status.theme === 'red'"
          :warningDark="status.theme === 'yellow'"
          :info="status.theme === 'blue'"
          :neutral="status.theme === 'gray'"
        >
          {{ status.text }}
        </TextSmall>
      </div>
    </TableCell>
    <TableCell overflowVisible>
      <ButtonEditCard
        class="!relative ml-auto !opacity-0 group-hover:!opacity-100"
      >
        <template #option>
          <span @click.prevent.stop="showProjectModal(data.id)">{{
            $t('shared.button.move_to_folder')
          }}</span>
        </template>
      </ButtonEditCard>
    </TableCell>
  </tr>
</template>
<script setup>
import { ProjectService } from '@/api/new/services/project.service'
import ButtonEditCard from '@/components/shared/ButtonEditCard'
import TextSmall from '@/components/shared/font/text/TextSmall'
import TextTiny from '@/components/shared/font/text/TextTiny'
import OutputText from '@/components/shared/output/OutputText'
import TableCell from '@/components/shared/table/TableCell'
import { capitalize } from '@/helpers/format'
import moment from 'moment'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  },
  whiteBg: Boolean
})

const setProjectId = (payload) =>
  store.commit('workspace/setProjectId', payload)
const toggleSelectFolderModal = (payload) =>
  store.commit('modals/toggleSelectFolderModal', payload)

const status = computed(() => {
  return ProjectService.getStatus(props.data.attributes.status)
})

const targetList = computed(() => {
  return props.data.attributes.target_languages.join(', ')
})

function onClick() {
  router.push({
    name: 'clientProject',
    params: { id: props.data.id }
  })
}

function showProjectModal(id) {
  toggleSelectFolderModal(true)
  setProjectId(id)
}

function formatDate(date) {
  if (!date) return '-'
  return moment(date).format('YYYY-MM-DD')
}

function formatDeadline(date) {
  if (!date) return '-'
  return moment(date).format('YYYY-MM-DD HH:mm')
}
</script>
